<template>

  <div class="  ">
    <el-alert
      title="电子发票与纸质发票具有同等法律效力，可支持报销入账；同时根据增值税管理办法要求，如需为企业开具增值税发票，需提供纳税人识别号或统一社会信用代码，否则该发票无法作为税收凭证。"
      type="warning"
      show-icon
    >
    </el-alert>
    <el-form
      :model="ruleForm"
      :rules="rules"
      status-icon
      ref="ruleForm"
      label-width="100px"
    >

      <el-form-item
        label="发票类型"
        prop=""
      >
        <el-radio
          v-model="radio1"
          label="0"
        >增值税电子普通发票</el-radio>

      </el-form-item>
      <el-form-item
        label="抬头类型"
        prop="type"
      >
        <el-radio-group v-model="radio2">
          <el-radio :label="0">个人</el-radio>
          <el-radio :label="1">企业</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="发票抬头"
        prop="title"
      >
        <el-input></el-input>
      </el-form-item>
      <el-form-item
        v-if='radio2==1'
        label="纳税人识别号"
        prop="number"
      >
        <el-input></el-input>
      </el-form-item>
      <el-form-item label="发票内容">
        <el-radio
          v-model="radio1"
          label="0"
        >商品明细
        </el-radio>
      </el-form-item>
      <el-form-item>
        <el-button type="info">保 存</el-button>
        <el-button type=" ">取 消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      radio1: "0",
      radio2: "0"
    };
  },

  watch: {},
  computed: {},
  mounted() {},
  methods: {},
  components: {},
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.content_container {
  background: #fff;
}
</style>
